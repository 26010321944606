import {takeEvery, call} from 'redux-saga/effects';
import {LOGOUT} from './actions';
import {LOGOUT_ERROR_MESSAGE} from "./constants";

function* clearToken() {
    try {
        yield call([localStorage, 'removeItem'], 'token');
    } catch (error) {
        console.error(LOGOUT_ERROR_MESSAGE, error);
    }
}

function* logoutSaga() {
    yield takeEvery(LOGOUT, clearToken);
}

export default logoutSaga;
