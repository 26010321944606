import form from "./form";
import router from "./router";
import notification from "./notification";
import i18n from "./i18n";
import init from "./init";
import auth from "./auth";
import modal from "./modal";
import user from "./user";
import coreExport from "./export";
import coreTypes from "./types";

export default [router, i18n, init, form, notification, auth, modal, user, coreExport, coreTypes];
