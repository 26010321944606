import React, {useEffect} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {GoogleLogin} from '@react-oauth/google';

import auth from "core/auth";

const Login = ({onLoginRequest, onLoginFailure, autoLogin, saveToken}) => {
    const handleSuccess = (credentialResponse) => {
        onLoginRequest(credentialResponse);
        const token = credentialResponse.credential;
        localStorage.setItem('token', token);
        saveToken(token);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            handleSuccess({credential: token});
        }
    }, []);

    return (
        <GoogleLogin
            theme="filled_black"
            onSuccess={handleSuccess}
            onError={() => onLoginFailure({})}
            useOneTap
            auto_select={autoLogin}
        />
    );
};

Login.propTypes = {
    onLoginRequest: PropTypes.func.isRequired,
    onLoginFailure: PropTypes.func.isRequired,
    autoLogin: PropTypes.bool.isRequired,
    saveToken: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    autoLogin: auth.getAutoLogin(state),
});

const mapDispatchToProps = (dispatch) => ({
    onLoginRequest: (request) => dispatch(auth.authActionGroup.request(request)),
    onLoginFailure: (response) => dispatch(auth.authActionGroup.requestFailure(response)),
    saveToken: (token) => dispatch(auth.saveToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
