import * as React from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: "#FCC000",
                },
                thumb: {
                    '&:hover': {
                        boxShadow: "0 0 0 8px rgba(252, 192, 0, 0.24)",
                    },
                    '&.Mui-active': {
                        boxShadow: "0 0 0 16px rgba(252, 192, 0, 0.24)",
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& label.Mui-focused': {
                        color: "#FCC000",
                    },
                    '& .MuiFilledInput-underline:after': {
                        borderBottomColor: "#FCC000",
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: "#FCC000",
                        },
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: "#FCC000",
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: "#FCC000",
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "#FCC000",
                },
                colorSecondary: {
                    '&.Mui-checked': {
                        color: "#FCC000",
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: "#FCC000",
                },
                colorSecondary: {
                    '&.Mui-checked': {
                        color: "#FCC000",
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                selectMenu: {
                    whiteSpace: "unset",
                },
                select: {
                    padding: "27px 12px 10px",
                    textAlign: "left",
                    '&:focus': {
                        backgroundColor: "unset",
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    margin: ".2rem .2rem .2rem 0",
                },
            },
        },
    },
});

// eslint-disable-next-line react/prop-types
export default function ThemeProviderWrapper({children}) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
