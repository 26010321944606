import {NAME} from './constants';
import saga from "./saga";
import {exportCv, exportCvToDoc} from "./actions";

export default {
    NAME,
    saga,
    exportCv,
    exportCvToDoc,
};
