export const NAME = 'core-types';

export const availableTypes = {
    LANGUAGE: "LANGUAGE",
    SKILL: "SKILL",
    PROJECT: "PROJECT",
    TECHNOLOGY: "TECHNOLOGY",
    POSITION: "POSITION",
};

export const availableTypesArray = Object.values(availableTypes);
