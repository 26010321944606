import {model} from "core/util";
import {Type} from "../type";

export const PrivateProject = model.createModel("PrivateProject", (json) => ({
    id: json.id,
    name: json.name,
    description: json.description,
    company: json.company,
    from: json.from,
    to: json.to,
    contribution: json.contribution,
    technologies: Type.fromServerList(json.technologies),
}));
